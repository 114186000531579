export default function ({ initialText = '', initialValue = null, url }) {
    return {
        query: initialText,
        results: [],
        isOpen: false,
        highlightIndex: -1,
        selectedId: initialValue,
        selectedText: initialText,
        isDirty: false,
        init() {
            this.query = initialText;
            this.selectedId = initialValue;
            this.initialText = initialText;
            this.selectedText = initialText;
            console.log("Alpine::ItemComboBox::init", 'initialText', initialText, 'initialValue'    , initialValue)
        },
        fetchResults() {
            fetch(`${url}?q=${this.query}`)
                .then(response => response.json())
                .then(data => {
                    this.results = data.results;
                    if (this.$refs.input === document.activeElement) { // Check if input is focused
                        this.isOpen = true;
                    }
                    this.highlightIndex = -1;
                });
        },
        fetchResultsIfEmpty() {
            if (this.query === '') {
                this.fetchResults();
            }
        },
        handleFocus(event) {
            event.target.select();
            this.fetchResultsIfEmpty();
        },
        handleBlur() {
            if (this.isDirty) {
                console.log("Alpine::ItemComboBox::handleBlur::start", 'query', this.query, 'initialText', this.initialText)
                const match = this.results.find(result => result.disp_text === this.query);
                if (!match) {
                    this.selectedId = null;
                    this.selectedText = this.query; // Set selectedText to query if no match
                    console.log("Alpine::ItemComboBox::handleBlur", 'no match', this.query)
                } else {
                    this.selectedId = match.id;
                    this.selectedText = ''; // Set selectedText to empty if match found
                    console.log("Alpine::ItemComboBox::handleBlur", 'match', match)
                }
                this.$refs.input.dispatchEvent(new Event('change', { bubbles: true }));
            } else {
                console.log("Alpine::ItemComboBox::handleBlur", 'no change', this.query)
            }
            this.isOpen = false;
            this.highlightIndex = -1;
        },
        selectResult(result) {
            this.query = result.disp_text;  // Use result.disp_text for display
            this.selectedId = result.id;
            this.selectedText = ''; // Set selectedText to empty when a result is selected
            this.$refs.input.dispatchEvent(new Event('change', { bubbles: true }));
            this.isOpen = false;
        },
        selectHighlightedResult() {
            if (this.results.length > 0 && this.highlightIndex !== -1) {
                this.selectResult(this.results[this.highlightIndex]);
            }
        },
        moveHighlight(step) {
            if (!this.isOpen) return;
            this.highlightIndex = (this.highlightIndex + step + this.results.length) % this.results.length;
            this.scrollIntoView();
        },
        scrollIntoView() {
            this.$nextTick(() => {
                const element = document.querySelector(`li[data-index='${this.highlightIndex}']`);
                if (element) {
                    element.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
                }
            });
        }
    }
}