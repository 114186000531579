import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = {
        url: String
    };
    static targets = ["save", "status"];

    connect() {
        this.element.querySelectorAll("input").forEach((input) => {
            input.addEventListener("change", this.handleInputChange);
        });
    }

    disconnect() {
        this.element.querySelectorAll("input").forEach((input) => {
            input.removeEventListener("change", this.handleInputChange);
        });
    }

    handleInputChange = async (event) => {
        console.log("delivery_items_input_controller.js: handleInputChange event", event);

        if (this.lastTimeout) {
            clearTimeout(this.lastTimeout);
        }

        this.saveTarget.classList.remove("hidden");
        this.statusTarget.classList.add("hidden");

        this.lastTimeout = setTimeout(async () => {

            const urlValue = this.urlValue;
            const csrfToken = document.querySelector("meta[name='csrf-token']").content;
            console.log("delivery_items_input_controller.js: urlValue", urlValue);

            const formData = new FormData();
            let isAllEmpty = true;
            this.element.querySelectorAll("input").forEach((input) => {
                if (isAllEmpty && input.value && input.name.startsWith("item")) {
                    isAllEmpty = false;
                }
                formData.append(input.name, input.value);
            });

            if (isAllEmpty) {
                console.log("delivery_items_input_controller.js: All inputs are empty, skipping update");
                this.saveTarget.classList.add("hidden");
                this.statusTarget.classList.remove("hidden");
                return;
            } else {
                console.log("delivery_items_input_controller.js: formData", formData);
            }

            try {
                const response = await fetch(urlValue, {
                    method: "PATCH",
                    headers: {
                        "X-CSRF-Token": csrfToken,
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error("Failed to update data");
                } else {
                    console.log("delivery_items_input_controller.js: response", response);
                }

                // Handle successful response
            } catch (error) {
                console.error("delivery_items_input_controller.js: error", error)
            } finally {
                this.saveTarget.classList.add("hidden");
                this.statusTarget.classList.remove("hidden");
            }
        }, 500);
    };
}